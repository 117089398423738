import { Subscription } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, BindingEngine } from 'aurelia-framework';
import { activationStrategy, Router } from 'aurelia-router';
import { Models } from 'models/core';
import * as moment from 'moment';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { MeshSizeService } from 'services/mesh-size-service';
import { NetService } from 'services/net-service';
import { ReportService } from 'services/report-service';
import { ServicePriorityService } from 'services/service-priority-service';
import { ServiceService } from 'services/service-service';
import { ServiceStationService } from 'services/service-station-service';
import { ServiceStatusService } from 'services/service-status-service';
import { SiteService } from 'services/site-service';
import { ToastService } from 'services/toast-service';
import { ServiceDialog } from '../service-dialog/service-dialog';
import { ServiceStationStorageService } from '../../services/service-station-storage-service';
import { NetTypeService } from '../../services/net-type-service';
import { NetShapeService } from 'services/net-shape-service';
import { I18N } from 'aurelia-i18n';
import { ListSettingsService } from 'services/list-settings-service';
import { FileService } from 'services/file-service';
import { Field, getAllTasks, getInvoicingFields, getListFields } from './service-list/index';
import { ServiceFilterModel } from 'models/service-filter-model';
import { ServiceInvoiceService } from 'services/service-invoice-service';

@autoinject
export class ServiceList {
  @bindable
  public readonly: boolean = false;
  @bindable
  public pageSize: number = 25;
  @bindable
  private currentPage: number = 1;
  @bindable
  private customerid: number;
  @bindable
  private netid: number;
  @bindable
  private context: string;
  @bindable
  private serviceFilters: ServiceFilterModel;
  @bindable
  protected netHide = false;
  @bindable
  protected filterHide = false;
  @bindable
  protected noPaging = false;
  @bindable
  protected headerTextKey: string;
  @bindable
  protected showNewButton = true;
  @bindable
  protected showActiveFilters = false;
  @bindable
  protected filtersVisible: any = null;

  private exportServiceListProgress: boolean = false;
  private tableData: Array<Models.Service>;
  private searchInputHasFocus: boolean = false;
  private params: any;
  private showServiceCardProgress = {};
  private fields: Field[] = null;
  private openServiceAsDialog: boolean = true;
  private i18nStrings: any = {};
  private quickEditFieldOpen: string = null;
  private quickEditFields: object = {};
  private searchTextSubscription: Subscription;

  private serviceFilterGetFiltersQuery: () => Promise<any>;
  private serviceFilterClearAll: () => Promise<void>;
  protected serviceFilterToggleVisible: () => Promise<void>;

  // UNUSED?
  protected serviceFilterInputInFocus: object = {};
  protected serviceFilterDatepickerInFocus: boolean;

  protected totalItems: number;
  protected customers: Models.Customer[] = [];
  protected statuses: Models.ServiceStatus[] = [];
  protected ready: boolean = false;
  protected activeFiltersSummary: string = null;
  protected sites: Models.Site[];
  protected selectedFields: Array<Field>;
  protected currentUser = null;
  protected tableViewType: number = 1;
  protected tableViewVerticalLines: boolean = false;
  protected tableHorizontalScroll: boolean = true;
  protected tableMargin: number = 2;
  protected lastClickedServiceId: number = null;
  protected isAttached: boolean = false;
  protected showSearchDefault: boolean = false;

  @bindable
  public externalFilters;

  private invoicingFields: ReturnType<typeof getInvoicingFields>;
  protected allTasks: ReturnType<typeof getAllTasks>;

  private serviceFilterTypes = {
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    RADIO_SIMPLE: 'radioSimple',
    RANGE: 'range',
    RANGE_DATE: 'rangeDate',
  };

  statusFilters = {
    Washed: false,
    NotWashed: false,
    Repaired: false,
    NotRepaired: false,
    Tested: false,
    NotTested: false,
    Antifouled: false,
    NotAntifouled: false,
    PlannedDeliveryDate: false,
    NotPlannedDeliveryDate: false,
    Discarded: false,
    NotDiscarded: false,
    InStorage: false,
    NotInStorage: false,
    Nets: false,
    NotNets: false,
  };

  constructor(
    private netService: NetService,
    private serviceService: ServiceService,
    private errorService: ErrorService,
    private bindingEngine: BindingEngine,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private reportService: ReportService,
    private i18n: I18N,
    private dialogService: DialogService,
    private listSettingsService: ListSettingsService,
    private fileService: FileService,
    private serviceInvoiceService: ServiceInvoiceService,
    protected siteService: SiteService,
    protected serviceStationStorageService: ServiceStationStorageService,
    protected netTypeService: NetTypeService,
    protected netShapeService: NetShapeService,
    protected serviceStationService: ServiceStationService,
    protected servicePriorityService: ServicePriorityService,
    protected customerService: CustomerService,
    protected meshSizeService: MeshSizeService,
    protected serviceStatusService: ServiceStatusService
  ) {
    this.invoicingFields = getInvoicingFields();
    this.allTasks = getAllTasks();
  }

  private getFilterKey() {
    return this.serviceService.SERVICE_FILTERS_KEY + (this.context ? '_' + this.context : '');
  }

  private getFilterKeyExternal() {
    return this.serviceService.SERVICE_FILTERS_KEY + (this.context ? '_' + this.context : '') + '_EXTERNAL';
  }

  /** Aurelia router lifecycle method */
  protected determineActivationStrategy() {
    return activationStrategy.replace;
  }

  protected activate(params: { context: string }) {
    this.params = params;
    if (params.context) {
      this.context = params.context;
    }
  }

  protected attached(params: { context: string } | object) {
    if (!this.params && params) {
      this.params = params;
    } else if (!params && this.params) {
      params = this.params;
    }
    if (!this.params) {
      this.params = {};
      params = {};
    }

    this.i18nStrings.customerId = this.i18n.tr('customer.autocompleteSearchPlaceholder');

    void this.init();
    // Close all quickedit-fields on Esc
    document.addEventListener('keyup', (event) => {
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        this.quickEditFieldOpen = null;
      }
    });
  }

  private async init() {
    const pageSize = await this.listSettingsService.getDefaultPageSize();
    this.pageSize = pageSize;

    const savedExternalFiltersString = localStorage.getItem(this.getFilterKeyExternal());

    if (savedExternalFiltersString) {
      this.externalFilters = JSON.parse(savedExternalFiltersString);
    }

    if (this.serviceFilters && this.serviceFilters.searchText && this.serviceFilters.searchText !== '') {
      this.showSearchDefault = true;
    }

    this.fields = this.getFields();

    // get selected values from localstorage
    const selectedFields: Array<string> = JSON.parse(
      localStorage.getItem(this.serviceService.SELECTED_SERVICE_COLUMNS + (this.context ? '_' + this.context : ''))
    );

    if (selectedFields) {
      // deselect all fields, then reselect the selected fields
      this.fields.forEach((field: Field) => {
        // hidden fields are not deselected, because this can cause problems
        // when adding new hidden fields needed for system purposes
        if (field.visible || field.visible == undefined) {
          field.selected = false;
        }
      });

      this.fields.forEach((field: Field) => {
        const selectedField = selectedFields.find((x) => {
          return x == field.field;
        });

        if (selectedField) {
          field.selected = true;
        } else {
          if (field.visible || field.visible == undefined) {
            field.selected = false;
          }
        }
      });
    }

    this.getServices();
    // void this.getInvoices();

    this.isAttached = true;
  }

  private getFields() {
    return getListFields({
      customerid: this.customerid,
      showServiceCard: this.showServiceCard.bind(this),
    });
  }

  private quickEditSetup(tableData: Array<Models.Service>, fields) {
    tableData.forEach((row: any) => {
      this.quickEditFields[row.Id] = [];

      fields.forEach(({ field, quickEdit }) => {
        if (quickEdit) {
          if (this.invoicingFields[field]) {
            // Invoicing fields
            if (
              row[this.invoicingFields[field].OrderedField] == true &&
              row[this.invoicingFields[field].InvoiceField] == true &&
              row[this.invoicingFields[field].StatusDate] != null
            ) {
              this.quickEditFields[row.Id].push(field);
            }
          } else {
            // Other fields
            this.quickEditFields[row.Id].push(field);
          }
        }
      });
    });
  }

  private isQuickEditField(rowId, fieldName) {
    return this.quickEditFields[rowId].includes(fieldName);
  }

  private getServices() {
    if (!this.fields) {
      return;
    }

    this.selectedFields = this.fields.filter((x) => x.selected && (x.visible === undefined || x.visible === true));

    // Needs to be inside a timeout because query-function
    // is binded in from service-filters
    setTimeout(async () => {
      const serviceFiltersQuery = await this.serviceFilterGetFiltersQuery();
      serviceFiltersQuery._select = serviceFiltersQuery._select.replace(',ServiceId', '');

      setTimeout(() => {
        // do a new timeout because the serviceFilterGetFiltersQuery causes the
        // serviceFilters two-way binding to update
        if (!this.searchTextSubscription) {
          this.searchTextSubscription = this.bindingEngine
            .propertyObserver(this.serviceFilters, 'searchText')
            .subscribe((newValue, oldValue) => {
              this.filterValueChanged('searchText', newValue, oldValue);
            });
        }

        serviceFiltersQuery.orderBy = this.serviceFilters.orderBy;
        serviceFiltersQuery.orderByDirection = this.serviceFilters.orderByDirection;
        serviceFiltersQuery.skip = this.serviceFilters.skip;
        serviceFiltersQuery.top = this.serviceFilters.top ?? this.pageSize;
        serviceFiltersQuery.searchText = this.serviceFilters.searchText;

        this.getStatusFilters(serviceFiltersQuery);

        this.setAdditionalSpecialFilters(serviceFiltersQuery);

        this.serviceService
          .getList(serviceFiltersQuery)
          .then((res) => {
            this.ready = true;
            this.totalItems = res.headers.get('x-total-count');
            return res.text().then((responseText: string) => {
              this.tableData = JSON.parse(responseText).map((x) => {
                x.ServiceId = x.Id;
                return x;
              });

              this.quickEditSetup(this.tableData, this.fields);
            });
          })
          .catch((err) => this.errorService.handleError(err));
      });
    });
  }

  private getStatusFilters(serviceFiltersQuery) {
    // Status filters
    serviceFiltersQuery.includeWashed = this.statusFilters.Washed ? true : this.statusFilters.NotWashed ? false : null;
    serviceFiltersQuery.includeTested = this.statusFilters.Tested ? true : this.statusFilters.NotTested ? false : null;
    serviceFiltersQuery.includeRepaired = this.statusFilters.Repaired
      ? true
      : this.statusFilters.NotRepaired
        ? false
        : null;
    serviceFiltersQuery.includeAntifouled = this.statusFilters.Antifouled
      ? true
      : this.statusFilters.NotAntifouled
        ? false
        : null;
    serviceFiltersQuery.includePlannedDeliveryDate = this.statusFilters.PlannedDeliveryDate
      ? true
      : this.statusFilters.NotPlannedDeliveryDate
        ? false
        : null;
    serviceFiltersQuery.includeDiscarded = this.statusFilters.Discarded
      ? true
      : this.statusFilters.NotDiscarded
        ? false
        : null;
    serviceFiltersQuery.includeInStorage = this.statusFilters.InStorage
      ? true
      : this.statusFilters.NotInStorage
        ? false
        : null;
    serviceFiltersQuery.includeNets = this.statusFilters.Nets ? true : this.statusFilters.NotNets ? false : null;
  }

  private setAdditionalSpecialFilters(serviceFiltersQuery) {
    // hack, should be handled elsewhere?
    if (this.customerid) {
      serviceFiltersQuery['customerIds'] = [this.customerid];
    }
    if (this.netid) {
      serviceFiltersQuery['netId'] = this.netid;
    }

    serviceFiltersQuery['searchText'] = this.serviceFilters.searchText;

    if (this.context === 'net-services') {
      this.selectedFields.unshift({
        title: 'general.id',
        selected: true,
        field: 'ServiceId',
        visible: true,
      });
    }

    if (this.context === 'service-invoicing-report') {
      serviceFiltersQuery['excludeNotInvoiceCompatible'] = true;
    }

    // even more hack, these filters does not exist, so need to add them manually
    if (this.context === 'statistics' && this.externalFilters) {
      if (this.externalFilters.serviceStationId) {
        serviceFiltersQuery['serviceStationId'] = this.externalFilters.serviceStationId;
      }
      if (this.externalFilters.washingServiceStationId) {
        serviceFiltersQuery['washingServiceStationId'] = this.externalFilters.washingServiceStationId;
      }
      if (this.externalFilters.testingServiceStationId) {
        serviceFiltersQuery['testingServiceStationId'] = this.externalFilters.testingServiceStationId;
      }
      if (this.externalFilters.repairServiceStationId) {
        serviceFiltersQuery['repairServiceStationId'] = this.externalFilters.repairServiceStationId;
      }
      if (this.externalFilters.antifoulingServiceStationId) {
        serviceFiltersQuery['antifoulingServiceStationId'] = this.externalFilters.antifoulingServiceStationId;
      }
      if (this.externalFilters.deliveryServiceStationId) {
        serviceFiltersQuery['deliveryServiceStationId'] = this.externalFilters.deliveryServiceStationId;
      }
    }
  }

  protected async exportServiceList(exportOnlyVisible = false) {
    if (this.exportServiceListProgress) {
      return;
    }
    this.exportServiceListProgress = true;

    const serviceFiltersQuery = await this.serviceFilterGetFiltersQuery();
    this.getStatusFilters(serviceFiltersQuery);
    this.setAdditionalSpecialFilters(serviceFiltersQuery);

    let fields = this.fields
      .filter((x: Field) => x.disabled != true && x.exportable != false)
      .map((x: Field) => x.field);

    // add some extra fields that should always be exported
    fields.push('ProducerName');
    fields.push('HoursWashing');
    fields.push('HoursAll');
    fields.push('DisinfectionTypeName');
    fields.push('DetergentTypeName');
    fields.push('AmountWaste');
    fields.push('CircumferenceMeasured');
    fields.push('Nettings');
    fields.push('NetAntifoulingTypeName');
    fields.push('NetAntifoulingLiters');
    fields.push('ServiceAntifoulingTypeName');
    fields.push('ServiceAntifoulingLiters');

    fields = Array.from(new Set(fields)).filter((x) => x !== 'ServiceId');

    serviceFiltersQuery._select = fields.join(',');

    serviceFiltersQuery._export = true;

    const visibleFields = this.fields
      ?.filter((x: Field) => x.selected && x.visible != false)
      .map((x: Field) => x.field);
    serviceFiltersQuery._visibleFields = visibleFields?.join(',');
    serviceFiltersQuery._exportOnlyVisibleFields = exportOnlyVisible;

    try {
      await this.serviceService.getList(serviceFiltersQuery);
      this.ready = true;
      this.exportServiceListProgress = false;
    } catch (err) {
      this.errorService.handleError(err);
      this.exportServiceListProgress = false;
    }
  }

  private filterValueChanged(_: string, newValue: string, oldValue: string) {
    if (newValue === oldValue) {
      return;
    }
    // Always go back to first page when changing filter values
    this.currentPage = 1;

    if (!this.serviceFilters) {
      return;
    }

    this.serviceFilters.skip = 0;

    this.pushState();
    this.saveFilters();

    this.getServices();
  }

  protected setSearchFocus(event: { detail: { value: boolean } }) {
    if (event.detail) {
      this.searchInputHasFocus = event.detail.value;
    }
    if (!this.searchInputHasFocus) {
      this.externalFilters.searchText = null;
      this.getServices();
    }
  }

  protected toggleStatuses(event: Event) {
    event.stopPropagation();
  }

  protected closeFieldSelector() {
    this.closeDropdown();
  }

  protected changeServiceStatus(service: Models.Service, status: Models.ServiceStatus) {
    // status delivered and discarded should be set through a signing process unless testing is not ordred,
    // in that case no tests are performed so normal signing cannot be done
    if ((service.OrderedTesting && status.Id === 8) || status.Id === 9) {
      return;
    }

    this.serviceService
      .updateServiceStatus(status.Id, service.Id)
      .then(() => {
        this.toastService.showSuccess('service.updated');
        this.getServices();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private applyFilters() {
    this.currentPage = 1;
    this.serviceFilters.skip = 0;

    this.pushState();
    this.saveFilters();
    this.getServices();
    this.closeDropdown();
  }

  updateStatusFilter() {
    this.currentPage = 1;
    this.getServices();
  }

  protected async clearFilters() {
    await this.serviceFilterClearAll();
  }

  public clearSavedFilters() {
    localStorage.removeItem(this.getFilterKey());
  }

  private buildQueryString(obj: object) {
    const arr = [];
    for (const p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        // dont include keys where value is null
        if (obj[p]) {
          arr.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      }
    }
    return arr.join('&');
  }

  private pushState() {
    if (this.context === 'customer-services' || this.context === 'net-services' || this.context === 'statistics') {
      return;
    }

    const baseUrl = '#' + this.router.currentInstruction.fragment;

    const stateObj = {
      currentPage: this.currentPage,
    };

    const queryString = this.buildQueryString(stateObj);
    history.pushState(stateObj, 'services', baseUrl + '?' + queryString);
  }

  protected sortTable(field: Field) {
    if (!this.serviceFilters) {
      // filters not set up yet, cannot sort
      return;
    }

    const fieldName = field.orderByName ?? field.field;

    if (this.serviceFilters.orderBy === fieldName) {
      this.serviceFilters.orderByDirection = this.serviceFilters.orderByDirection === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.serviceFilters.orderBy = fieldName;
      this.serviceFilters.orderByDirection = 'DESC';
    }

    this.pushState();
    this.saveFilters();

    this.getServices();
  }

  private closeDropdown() {
    this.eventAggregator.publish('dropdownClose', 1);
  }

  protected currentPageChanged(newValue: string, oldValue: string) {
    if (!this.serviceFilters) {
      // cannot change page, serviceFilters not defined
      return;
    }

    if (parseInt(newValue, 10) === parseInt(oldValue, 10)) {
      return;
    }

    this.pushState();
    this.serviceFilters.skip = (+newValue - 1) * this.pageSize;

    this.saveFilters();
    this.getServices();
  }

  private async showServiceCard(id: number, event: Event) {
    // function not done in backend yet
    event.stopPropagation();
    if (this.showServiceCardProgress[id] === true) {
      return;
    }
    this.showServiceCardProgress[id] = true;

    try {
      await this.reportService.getServiceCard(id);
      this.toastService.showSuccess('document.downloaded');
      this.showServiceCardProgress[id] = false;
    } catch (error) {
      this.errorService.handleError(error);
      this.showServiceCardProgress[id] = false;
    }
  }

  private saveFilters() {
    const data: any = {};

    for (const key of Object.keys(this.serviceFilters)) {
      const filter = this.serviceFilters[key];

      if (!filter || typeof filter === 'undefined') {
        continue;
      } else if (typeof filter === 'string' || typeof filter === 'number') {
        data[key] = filter;
      } else {
        data[key] = {
          values: filter.values,
          visible: filter._visible ?? false,
        };
      }
    }

    data.orderBy = this.serviceFilters.orderBy;
    data.orderByDirection = this.serviceFilters.orderByDirection;
    data.top = this.serviceFilters.top;
    data.skip = this.serviceFilters.skip;

    localStorage.setItem(this.getFilterKey(), JSON.stringify(data));
  }

  // Close datepicker on clicks outside quickedits
  public handleUpperTableClicks(event: { toElement: HTMLElement }) {
    if (
      event?.toElement?.classList?.contains('field-quickedit') ||
      event?.toElement?.tagName === 'INPUT' ||
      event?.toElement?.tagName === 'DATEPICKER'
    ) {
      return event;
    } else {
      this.quickEditFieldOpen = null;
    }
    return event;
  }

  protected showServiceDetails(id: number, netId: number, event: { target: HTMLElement }) {
    // Don't do anything if clicking on a quickedit-field
    if (
      event?.target?.classList?.contains('field-quickedit') ||
      event?.target?.tagName === 'INPUT' ||
      event?.target?.tagName === 'DATEPICKER'
    ) {
      return;
    }

    // Close quickEdit-field
    this.quickEditFieldOpen = null;

    if (this.openServiceAsDialog) {
      this.lastClickedServiceId = id;

      document.querySelector('html').style.overflowY = 'hidden';
      this.dialogService
        .open({
          viewModel: ServiceDialog,
          model: { Id: id, NetId: netId },
          lock: false,
          position: () => {},
        })
        .whenClosed(() => {
          document.querySelector('html').style.overflowY = null;
          this.getServices();
        })
        .catch(() => {
          document.querySelector('html').style.overflowY = null;
        });
    } else {
      this.router.navigateToRoute('service-detail', { Id: id, NetId: netId });
    }
  }

  protected quickEditField(rowId: number, field: string, event: { target: HTMLElement }) {
    if (this.isQuickEditField(rowId, field)) {
      if (event?.target?.classList?.contains('field-quickedit')) {
        if (this.quickEditFieldOpen === `${rowId}_${field}`) {
          this.quickEditFieldOpen = null;
        } else {
          this.quickEditFieldOpen = `${rowId}_${field}`;
        }
      }
    }
  }

  protected quickEditInputOnFocus(rowId: number, field: string) {
    this.quickEditFieldOpen = `${rowId}_${field}`;
  }

  protected quickEditFieldDateChange({ detail }, rowId: number, { field }) {
    let doUpdate = false;
    const rowIndex = this.tableData.findIndex((x: Models.Service) => x.Id === rowId);

    if (detail && !this.tableData[rowIndex][field]) {
      // set new value
      doUpdate = true;
    } else if (!detail && this.tableData[rowIndex][field]) {
      // clear value
      doUpdate = true;
    } else if (detail && !moment.utc(detail).isSame(moment.utc(this.tableData[rowIndex][field]))) {
      // update
      doUpdate = true;
    }

    if (doUpdate) {
      this.serviceService
        .patchValue(this.tableData[rowIndex].Id, field, detail)
        .then((res) => {
          this.tableData[rowIndex][field] = res[field];
          this.toastService.showSuccess('service.updated');
          this.quickEditFieldOpen = null;
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  protected quickEditComplexFieldDateChange({ detail }, rowId: number, fieldToRead: string, fieldToUpdate: string) {
    let doUpdate = false;
    const rowIndex = this.tableData.findIndex((x: Models.Service) => x.Id === rowId);

    if (detail && !this.tableData[rowIndex][fieldToRead]) {
      // set new value
      doUpdate = true;
    } else if (!detail && this.tableData[rowIndex][fieldToRead]) {
      // clear value
      doUpdate = true;
    } else if (detail && !moment.utc(detail).isSame(moment.utc(this.tableData[rowIndex][fieldToRead]))) {
      // update
      doUpdate = true;
    }

    if (doUpdate) {
      this.serviceService
        .patchValue(this.tableData[rowIndex].Id, fieldToUpdate, detail)
        .then((res) => {
          this.tableData[rowIndex][fieldToRead] = res[fieldToUpdate];
          this.toastService.showSuccess('service.updated');
          this.quickEditFieldOpen = null;
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  protected updateSelectList() {
    const selectedFields = this.fields.filter((x) => x.selected).map((x) => x.field);
    localStorage.setItem(
      this.serviceService.SELECTED_SERVICE_COLUMNS + (this.context ? '_' + this.context : ''),
      JSON.stringify(selectedFields)
    );

    this.getServices();
  }

  protected async downloadAttachment(serviceId: number, attachmentId: number) {
    const netInfo = await this.netService.getNetInfo(this.tableData.find((td) => td.Id == serviceId)?.NetId);
    try {
      await this.fileService.download(attachmentId, netInfo.CustomerId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
// "Id,
// NetIsSpecialProduct,
// NetId,
// NetIdentifier,
// CustomerNetId,
// NetTypeName,
// CustomerName,
// MeshSize,
// NetShapeName,
// Circumference,
// ServiceStationName,
// ReceivedDate,
// ReceivedFromSiteName,
// PlannedDeliveryDate,
// WashedDate,
// TestedDate,
// RepairedDate,
// AntifouledDate,
// SpaghettiDate,
// DeliveredDate,
// DeliveredToSiteName,
// ServiceStationStorageName,
// CircumferenceMeasured,
// ServiceStatusId,
// ServiceStatusName,
// OrderedWashing,
// OrderedMeasuring,
// OrderedTesting,
// OrderedRepair,
// OrderedAntifouling,
// OrderedDelivery,
// Locked,
// ProducerName,
// HoursWashing,
// HoursAll,
// DisinfectionTypeName,
// DetergentTypeName,
// AmountWaste,
// CircumferenceMeasured,
// Nettings,
// NetAntifoulingTypeName,
// NetAntifoulingLiters,
// ServiceAntifoulingTypeName,
// ServiceAntifoulingLiters"
